var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settings-user"}},[_c('b-card',{attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('shared.button.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('shared.button.submit'))+" ")])],1)]},proxy:true}])},[_c('rc-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-text',[_c('validation-observer',{ref:"editUserValidation"},[_c('form',{attrs:{"id":"editUserForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.$t('conf.user.tabs.base-info')}},[_c('b-row',[_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.name_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.name_input'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.name_input'),"autocomplete":"off"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.surname_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"surname"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.surname_input'))+" ")]),_c('b-form-input',{attrs:{"id":"surname","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.surname_input'),"autocomplete":"off"},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.position_input'),"rules":"required|alpha-spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"position"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.position_input'))+" ")]),_c('b-form-input',{attrs:{"id":"position","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.position_input'),"autocomplete":"off"},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.email_input'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.email_input'))+" ")]),_c('b-form-input',{attrs:{"id":"email","type":"email","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.email_input'),"autocomplete":"off"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.phone_input'),"rules":"digits:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.phone_input'))+" ")]),_c('b-form-input',{attrs:{"id":"phoneNumber","type":"number","minlength":"9","maxlength":"9","placeholder":_vm.$t('conf.user.popup.phone_input'),"autocomplete":"off"},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('conf.user.tabs.account')}},[_c('b-row',[_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.username_input'),"rules":"alpha-num|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.username_input'))+" ")]),_c('b-form-input',{attrs:{"id":"username","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.user.popup.username_input'),"autocomplete":"off"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.password_input'),"rules":"min:6|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"basic-password"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.password_input'))+" ")]),_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.newPassword.length > 0},on:{"click":_vm.generatePassword}},[_c('feather-icon',{attrs:{"icon":_vm.newPassword.length > 0 ? 'LockIcon' : 'UnlockIcon'}})],1)],1),_c('b-form-input',{attrs:{"id":"basic-password","type":_vm.visiblePass ? 'text' : 'password',"minlength":"6","maxlength":"40","disabled":!_vm.user.username,"placeholder":_vm.$t('conf.user.popup.password_input'),"autocomplete":"new-password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.passwordVisibility}},[_c('feather-icon',{attrs:{"icon":_vm.visiblePass ? 'EyeOffIcon' : 'EyeIcon'}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.popup.roles_input'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"userStatus"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.roles_input'))+" ")]),_c('v-select',{attrs:{"id":"userStatus","placeholder":_vm.$t('conf.user.popup.roles_input'),"options":_vm.roles,"multiple":"","clearable":false,"searchable":true},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(_vm.$t('roles.' + option.label))+" ")])}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t('roles.' + label)))])]}}],null,true),model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.user.requirePasswordChange),callback:function ($$v) {_vm.$set(_vm.user, "requirePasswordChange", $$v)},expression:"user.requirePasswordChange"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.change_pass_label_checkbox'))+" ")]),_c('b-form-checkbox',{attrs:{"disabled":_vm.teamLoginOnlyCheckboxDisabled},model:{value:(_vm.user.teamLoginOnly),callback:function ($$v) {_vm.$set(_vm.user, "teamLoginOnly", $$v)},expression:"user.teamLoginOnly"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.popup.only_team_login_label_checkbox'))+" ")])],1)],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('conf.user.tabs.financial-info'),"disabled":!_vm.hasAtLeastOneRole(_vm.me.roles, ['ROLE_FINANCE_MANAGER'])}},[(_vm.hasAtLeastOneRole(_vm.me.roles, ['ROLE_FINANCE_MANAGER']))?_c('b-row',[_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.user.financial.hourly-salary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"hourlySalaryGross"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.financial.hourly-salary'))+" ")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"hourlySalaryGross","type":"number","step":"0.01","placeholder":_vm.$t('conf.user.financial.hourly-salary'),"autocomplete":"off"},model:{value:(_vm.user.hourlySalaryGross),callback:function ($$v) {_vm.$set(_vm.user, "hourlySalaryGross", $$v)},expression:"user.hourlySalaryGross"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" "+_vm._s(_vm.$t('shared.currency-symbol'))+" / "+_vm._s(_vm.$t('shared.time-h'))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1787296208)})],1),_c('b-col',{staticClass:"pb-25",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"hourlySalaryForReadinessGross"}},[_vm._v(" "+_vm._s(_vm.$t('conf.user.financial.hourly-salary-for-readiness-gross'))+" ")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"hourlySalaryForReadinessGross","type":"number","step":"0.01","placeholder":_vm.$t('conf.user.financial.hourly-salary-for-readiness-gross'),"autocomplete":"off"},model:{value:(_vm.user.hourlySalaryForReadinessGross),callback:function ($$v) {_vm.$set(_vm.user, "hourlySalaryForReadinessGross", $$v)},expression:"user.hourlySalaryForReadinessGross"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" "+_vm._s(_vm.$t('shared.currency-symbol'))+" / "+_vm._s(_vm.$t('shared.time-h'))+" ")])],1)],1)],1):_vm._e()],1)],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }