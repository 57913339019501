<template>
  <div id="settings-user">
    <b-card footer-tag="footer">

      <rc-overlay :show="loading">
        <b-card-text>

          <validation-observer ref="editUserValidation">
            <form
              id="editUserForm"
              @submit.prevent
            >

              <b-tabs>
                <b-tab :title="$t('conf.user.tabs.base-info')">

                  <b-row>
                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.name_input')"
                        rules="required|alpha-spaces|min:3"
                      >
                        <label for="name">
                          {{ $t('conf.user.popup.name_input') }}
                        </label>
                        <b-form-input
                          id="name"
                          v-model="user.name"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.user.popup.name_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.surname_input')"
                        rules="required|alpha-spaces|min:3"
                      >
                        <label for="surname">
                          {{ $t('conf.user.popup.surname_input') }}
                        </label>
                        <b-form-input
                          id="surname"
                          v-model="user.surname"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.user.popup.surname_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.position_input')"
                        rules="required|alpha-spaces|min:3"
                      >
                        <label for="position">
                          {{ $t('conf.user.popup.position_input') }}
                        </label>
                        <b-form-input
                          id="position"
                          v-model="user.position"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.user.popup.position_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.email_input')"
                        rules="email"
                      >
                        <label for="email">
                          {{ $t('conf.user.popup.email_input') }}
                        </label>
                        <b-form-input
                          id="email"
                          v-model="user.email"
                          type="email"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.user.popup.email_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.phone_input')"
                        rules="digits:9"
                      >
                        <label for="phoneNumber">
                          {{ $t('conf.user.popup.phone_input') }}
                        </label>
                        <b-form-input
                          id="phoneNumber"
                          v-model="user.phoneNumber"
                          type="number"
                          minlength="9"
                          maxlength="9"
                          :placeholder="$t('conf.user.popup.phone_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-tab>

                <b-tab :title="$t('conf.user.tabs.account')">
                  <b-row>
                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.username_input')"
                        rules="alpha-num|min:3|max:50"
                      >
                        <label for="username">
                          {{ $t('conf.user.popup.username_input') }}
                        </label>
                        <b-form-input
                          id="username"
                          v-model="user.username"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('conf.user.popup.username_input')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.password_input')"
                        rules="min:6|max:40"
                      >
                        <label for="basic-password">
                          {{ $t('conf.user.popup.password_input') }}
                        </label>
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-button
                              variant="outline-primary"
                              :disabled="newPassword.length > 0"
                              @click="generatePassword"
                            >
                              <feather-icon :icon="newPassword.length > 0 ? 'LockIcon' : 'UnlockIcon'" />
                            </b-button>
                          </b-input-group-prepend>
                          <b-form-input
                            id="basic-password"
                            v-model="user.password"
                            :type="visiblePass ? 'text' : 'password'"
                            minlength="6"
                            maxlength="40"
                            :disabled="!user.username"
                            :placeholder="$t('conf.user.popup.password_input')"
                            autocomplete="new-password"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="passwordVisibility"
                            >
                              <feather-icon :icon="visiblePass ? 'EyeOffIcon' : 'EyeIcon'" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.popup.roles_input')"
                        rules=""
                      >
                        <b-form-group>
                          <label for="userStatus">
                            {{ $t('conf.user.popup.roles_input') }}
                          </label>
                          <v-select
                            id="userStatus"
                            v-model="user.roles"
                            :placeholder="$t('conf.user.popup.roles_input')"
                            :options="roles"
                            multiple
                            :clearable="false"
                            :searchable="true"
                          >
                            <span
                              slot="selected-option"
                              slot-scope="option"
                            >
                              {{ $t('roles.' + option.label) }}
                            </span>
                            <template #option="{ label }">
                              <span>{{ $t('roles.' + label) }}</span>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>

                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <b-form-checkbox
                        v-model="user.requirePasswordChange"
                        class="mb-1"
                      >
                        {{ $t('conf.user.popup.change_pass_label_checkbox') }}
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="user.teamLoginOnly"
                        :disabled="teamLoginOnlyCheckboxDisabled"
                      >
                        {{ $t('conf.user.popup.only_team_login_label_checkbox') }}
                      </b-form-checkbox>

                    </b-col>

                  </b-row>

                </b-tab>

                <b-tab
                  :title="$t('conf.user.tabs.financial-info')"
                  :disabled="!hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
                >
                  <b-row v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])">
                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('conf.user.financial.hourly-salary')"
                        rules="required"
                      >
                        <label for="hourlySalaryGross">
                          {{ $t('conf.user.financial.hourly-salary') }}
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="hourlySalaryGross"
                            v-model="user.hourlySalaryGross"
                            type="number"
                            step="0.01"
                            :placeholder="$t('conf.user.financial.hourly-salary')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            {{ $t('shared.currency-symbol') }} / {{ $t('shared.time-h') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-25"
                    >
                      <label for="hourlySalaryForReadinessGross">
                        {{ $t('conf.user.financial.hourly-salary-for-readiness-gross') }}
                      </label>
                      <b-input-group>
                        <b-form-input
                          id="hourlySalaryForReadinessGross"
                          v-model="user.hourlySalaryForReadinessGross"
                          type="number"
                          step="0.01"
                          :placeholder="$t('conf.user.financial.hourly-salary-for-readiness-gross')"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          {{ $t('shared.currency-symbol') }} / {{ $t('shared.time-h') }}
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>

                </b-tab>
              </b-tabs>

            </form>
          </validation-observer>

        </b-card-text>
      </rc-overlay>

      <template #footer>

        <div class="text-right">
          <b-button
            class="mr-1"
            variant="outline-warning"
            @click="$router.back()"
          >
            {{ $t('shared.button.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            :disabled="loading"
            @click="submitForm"
          >
            {{ $t('shared.button.submit') }}
          </b-button>
        </div>

      </template>

    </b-card>
  </div>
</template>

<script>
import { cloneNested, parseRequestError, randomPassword } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCardText,
  BCol, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required, alphaNum, alphaSpaces, digits,
} from '@validations'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import guard from '@/guard'

export default {
  components: {
    BFormInput,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BTabs,
    BTab,

    RcOverlay,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      alphaSpaces,
      digits,
      visiblePass: false,
      newPassword: '',
      teamLoginOnlyCheckboxDisabled: true,
      loading: false,
    }
  },
  computed: {
    user() {
      return cloneNested(this.$store.getters['user/getUserData'])
    },
    roles() {
      return cloneNested(this.$store.getters['auth/getUser'].roles)
    },
    me() {
      return this.$store.state.auth.activeUser
    },
  },
  watch: {
    user: {
      handler(user) {
        if (typeof user.roles !== 'undefined') {
          this.processRolesChange(user.roles)
        }
      },
      deep: true,
    },
  },
  created() {
    this.initUserData()
  },
  beforeDestroy() {
    // this.$store.commit('user/CLEAR_USER_DATA')
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    initUserData() {
      this.loading = true
      this.$store.dispatch('user/fetchUserById', this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    submitForm() {
      this.$refs.editUserValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.loading = true
          const userData = cloneNested(this.user)

          const payload = {
            id: userData.id,
            data: userData,
          }
          this.$store.dispatch('user/updateUser', payload)
            .then(() => {
              window.toast.notify.success(this.$t('conf.user.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.initUserData()
            })
            .finally(() => {
              this.loading = false
            })

          return true
        })
    },
    processRolesChange(newUserRoles) {
      const canInitializeTeam = newUserRoles.includes('ROLE_PARAMEDIC')
      this.teamLoginOnlyCheckboxDisabled = !canInitializeTeam
      if (!canInitializeTeam) {
        this.user.teamLoginOnly = false
      }
    },
    passwordVisibility() {
      this.visiblePass = !this.visiblePass
    },
    generatePassword() {
      this.user.password = randomPassword()
    },
  },
}
</script>
